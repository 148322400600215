import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled';
import Marked from 'react-markdown'

import Container from "../components/Container";

import logo3 from "../img/logos/inlist.jpg";
import logo3_2x from "../img/logos/inlist@2x.jpg";

const FooterWrapper = styled("footer")`
  overflow: hidden;
`;

const Row = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin-left: -3rem;
  margin-right: -3rem;
  @media screen and (max-width: 550px) {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;

const Col = styled("div")`
  max-width: 50%;
  flex-basis: 50%;
  padding-left: 3rem;
  padding-right: 3rem;
  @media screen and (max-width: 767px) {
    max-width: 100%;
    flex-basis: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 550px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const Authors = styled("div")`
  position: relative;
  max-width: 66%;
  flex-basis: 66%;
  padding-top: 5rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: #201D1D;
  color: #ffffff;
  @media screen and (max-width: 1023px) {
    max-width: 100%;
    flex-basis: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  @media screen and (max-width: 550px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #201D1D;
    width: 66vw;
  }

  h2 {
    margin-bottom: 1em;
  }

  a {
    color: #f9d83d;
  }
`;

const Partners = styled("div")`
  max-width: 34%;
  flex-basis: 34%;
  align-self: center;
  padding-bottom: 2rem;
  padding-left: 6.25rem;
  padding-right: 3rem;

  @media screen and (max-width: 1199px) {
    padding-left: 3rem;
  }

  @media screen and (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    order: -1;
    max-width: 100%;
    flex-basis: 100%;
    padding-right: 3rem;
  }

  @media screen and (max-width: 550px) {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;
  }

  h2 {
    margin-top: 3rem;
    margin-bottom: 0;
    @media screen and (min-width: 551px) and (max-width: 1023px) {
      margin-top: 0;
    }
  }

  a {
    display: block;
    margin-bottom: .5rem;
    @media screen and (max-width: 1023px) {
      margin-right: 2rem;
      margin-bottom: 0;
    }
    @media screen and (max-width: 550px) {
      margin-top: 1.5rem;
    }

    &:hover {
      img {
        transform: translateY(5px);
      }
    }
  }

  img {
    transition: transform .15s ease-in-out;
    @media screen and (max-width: 1023px) {
      max-width: 200px;
    }
  }
`;

const Logos = styled("div")`
  img {
    display: block;
    max-width: 100%;
    height: auto;
    @media screen and (max-width: 1023px) {
      max-width: 200px;
    }
  }

  a {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 1023px) {
      margin-bottom: 0;
    }
  }
`;

export default () => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
        frontmatter {
          footer_1
          footer_2
        }
      }
    }
  `)

  return (
    <>
      <FooterWrapper>
       <Container>
         <Row>
          <Authors>
            <Row>
              <Col>
                <Marked source={data.markdownRemark.frontmatter.footer_1} />
              </Col>
              <Col>
                <Marked source={data.markdownRemark.frontmatter.footer_2} />
              </Col>
            </Row>
          </Authors>
          <Partners>
            <a target="_blank" rel="noopener noreferrer" href="https://inlist.cz"><img width={300} height={84} loading="lazy" alt="Logo - Institut liberálních studií" src={logo3} srcSet={`${logo3}, ${logo3_2x} 2x`} /></a>
          </Partners>
         </Row>
       </Container>
      </FooterWrapper>
    </>
  );
};
